import PropTypes from 'prop-types';

export const DEALER_REVIEWS_PER_PAGE = 21;
export const REVIEWS_PER_CLICK = 7;

/* Types */
export const REVIEWS_ALL = 'all';
export const REVIEWS_SALES = 'sales';
export const REVIEWS_SERVICE = 'service';

export const DEFAULT_REVIEWS_TYPE = REVIEWS_ALL;

export const REVIEWS_TYPES = [REVIEWS_ALL, REVIEWS_SALES, REVIEWS_SERVICE];

/* Sort and Sort Orders */
export const SORT_RATING = 'rating';
export const SORT_UPDATED = 'updated';

export const SORT_ORDER_ASC = 'ASC';
export const SORT_ORDER_DESC = 'DESC';

export const DEALER_PROPS = PropTypes.shape({
  dealerCity: PropTypes.string,
  dealerState: PropTypes.string,
  logicalName: PropTypes.string,
  reviewId: PropTypes.string,
});

export const DEALER_REVIEW_PAGES = {
  'create-sales-review.html': REVIEWS_SALES,
  'create-service-review.html': REVIEWS_SERVICE,
};

const FULLSALES = 'fullsales';
const FULLSERVICE = 'fullservice';
export const DEALER_COMMENT_PAGES = {
  [REVIEWS_SALES]: FULLSALES,
  [REVIEWS_SERVICE]: FULLSERVICE,
};

export const REVIEW_TITLE = {
  [REVIEWS_SALES]: 'Sales',
  [REVIEWS_SERVICE]: 'Service',
};
