import { SET_CRITICAL_CSS, PAGE_META, FORCE_DISABLE_LOOSE_ETAG, SET_VENOM_TEST_HEADER } from 'client/actions/constants';
import { isObject } from 'client/utils/object';
import { logger } from 'client/utils/isomorphic-logger';

export const MAX_CRITICAL_CSS_KEYS = 8;

export function serverReducer(state = {}, action = {}) {
  switch (action.type) {
    case SET_CRITICAL_CSS: {
      let criticalCss = action.config;
      if (isObject(criticalCss)) {
        const { criticalCssHeight, ...keyHash } = criticalCss;
        const keys = Object.keys(keyHash);
        if (keys.length > MAX_CRITICAL_CSS_KEYS) {
          logger('error', new Error(`too many critical css keys - ${JSON.stringify(criticalCss)}`));
          criticalCss = false;
        } else {
          criticalCss = {
            key: keys.map(key => `${key}:${keyHash[key]}`).join(','),
            height: criticalCssHeight,
          };
        }
      }
      // criticalCss is boolean
      return {
        ...state,
        criticalCss,
      };
    }
    case PAGE_META: {
      return { ...state, ...action.server };
    }
    case FORCE_DISABLE_LOOSE_ETAG: {
      return { ...state, forceDisableLooseEtag: action.payload };
    }
    case SET_VENOM_TEST_HEADER: {
      return { ...state, venomTestHeader: { ...state.venomTestHeader, ...action.payload } };
    }
    default:
      return state;
  }
}
