import { defaultTo, get, omitBy, isNil } from 'lodash';
import { ClientConfig } from 'client/configuration';
import { getQuery } from 'client/utils/location';
import {
  getMultiOfferResultsLink,
  getOfferDetailsPageLink,
} from 'site-modules/shared/utils/multi-offer-link-constructor';
import { venomHistory } from 'client/utils/history/venom-history';
import { SOLICITATION_SUPPORTED_PARTNERS } from 'site-modules/shared/constants/multi-offer/multi-offer-config';
import { PARTNERS } from 'site-modules/shared/constants/multi-offer/partners';

/**
 * Returns whether the given mileage meets the mileage criteria of the specified partner.
 *
 * @param {String} mileage mileage to check.
 * @param {Object} multiOfferPrequalification prequalification object containing partners' mileage criteria.
 * @param {String} partner the partner to check the mileage for, e.g. 'carwiser3'.
 * @returns {Boolean}
 */
export const meetsMileageCriteria = (mileage, multiOfferPrequalification, partner) => {
  const mileageInt = parseInt(mileage, 10);
  const partnerMileageCriteria = get(multiOfferPrequalification, [
    'partners',
    partner,
    'solicitation',
    'criteria',
    'mileage',
  ]);
  if (!partnerMileageCriteria) {
    return true;
  }
  const { min, max } = partnerMileageCriteria;
  const isMinOk = min === undefined || mileageInt >= min;
  const isMaxOk = max === undefined || mileageInt <= max;

  return isMinOk && isMaxOk;
};

/**
 * Returns if the given partner is prequalified for the Multi-Offer experience.
 * Includes both backend and frontend checks.
 *
 * @param {String} partner the partner to check.
 * @param {Object} multiOfferPrequalification the Prequalify API response.
 * @param {String} mileage the user-provided mileage, used for the frontend check.
 * @returns {Boolean}
 */
export const isPartnerPrequalified = (partner, multiOfferPrequalification, mileage) => {
  if (get(multiOfferPrequalification, ['partners', partner, 'solicitation', 'isPrequalified']) !== true) {
    return false;
  }

  return meetsMileageCriteria(mileage, multiOfferPrequalification, partner);
};

/**
 * Returns array of solicitation support partners that user prequalifies for the Multi-Offer experience.
 *
 * @param {Object} multiOfferPrequalification the Prequalify API response.
 * @param {String} mileage the user-provided mileage, used for the frontend check.
 *
 * @returns {Array} prequalified partners
 */
export const getPrequalifiedPartners = ({ multiOfferPrequalification, mileage }) =>
  SOLICITATION_SUPPORTED_PARTNERS.filter(partner =>
    isPartnerPrequalified(partner, multiOfferPrequalification, mileage)
  );

export const WS_EVENTS = {
  INITIAL_PUSH: 'initialPushModsRecord',
  UPDATE_PUSH: 'updatePushModsRecord',
};
export const WS_CONFIG_MULTI_OFFER_PATH_KEY = 'multiOfferPath';

/**
 * Get websocket configuration from client configuration.
 * DEV and SANDBOX environments is explicitly specified in the
 * environment config file, where other environments uses "location.host"
 * @param {String} pathKey key for the WebSocket path value
 * @returns {Object}
 */
export const getWebSocketConfig = pathKey => {
  const { host, [pathKey]: path } = ClientConfig.get('edmundsMultiOfferWebSocket');
  const webSocketHost = host || window.location.host;
  return {
    path,
    origin: `wss://${webSocketHost}`,
  };
};

export const transformPartnerAppraisalToModsRecord = ({ vehicle, dma, zipCode, abTestBucketing }) => ({
  seller: omitBy(
    {
      zipCode,
      dma: dma || null,
      abTestBucketing: abTestBucketing || [],
    },
    isNil
  ),
  vehicle,
});

export const getIsEmoSearchEnabled = location => getQuery(location).emoSearch === 'true';

export const hasPrequalifiedPartner = partners =>
  Object.values(defaultTo(partners, {})).some(({ solicitation: { isPrequalified } }) => isPrequalified);

export const hasMultiOfferPartners = partners => !!partners?.some(partner => partner !== PARTNERS.CARMAX);

/**
 * Redirects user to MOR page with given modsId
 * @param {String} modsId Mods Record I.D.
 * @param {Boolean} isAppExtensionPage for whether or not this is the webview version of the page
 */
export const goToMultiOfferResultsPage = ({ modsId, isAppExtensionPage }) => {
  const page = getMultiOfferResultsLink({ modsId, isAppExtensionPage });
  venomHistory.push(page);
};

/**
 * Redirects user to ODP with given modsId
 * @param {String} modsId Mods Record I.D.
 * @param {Boolean} isAppExtensionPage for whether or not this is the webview version of the page
 * @param {Boolean} isChicagoBearsPromotion for whether or not to append ?promotions=chicagobears to the url
 */
export const goToOfferDetailsPage = ({ modsId, isAppExtensionPage, isChicagoBearsPromotion = false }) => {
  let page = getOfferDetailsPageLink({ modsId, isAppExtensionPage });
  if (isChicagoBearsPromotion) page += '?promotions=chicagobears';
  venomHistory.push(page);
};
